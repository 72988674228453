import axiosClient from "./axiosClient";

const keywordApi = {
  getKeywords: () => {
    let url = `/keywords/`;
    return axiosClient.get(url);
  },
  createKeywords: ({ label, value }) => {
    const url = `/keywords/`;
    return axiosClient.post(url, { label, value });
  },
  deleteKeyword: (id) => {
    const url = `/keywords/${id}`;
    return axiosClient.delete(url);
  },
};

export default keywordApi;
