import React from "react";
import { Link, useLocation } from "react-router-dom";
import { useAuthProvider } from "contexts/authenticationContext";
import { Role } from "constants/global";
import Avatar from "./Avatar";
import styles from "./Header.module.css";
import LogoImage from "assets/images/logo.png";
// import Logo from './Logo';

function Header() {
  const { currentUser } = useAuthProvider();
  const location = useLocation();
  const menus = {};
  menus[Role.ADMIN] = [
    {
      path: "/dashboard",
      title: "Dashboard",
    },
    {
      path: "/store",
      title: "Store Management",
    },
    {
      path: "/user",
      title: "User Management",
    },
    {
      path: "/playback",
      title: "Playback",
    },
    {
      path: "/dictionary",
      title: "Dictionary",
    },
    {
      path: "/transcript-tasks",
      title: "Transcript Tasks",
    },
    {
      path: "/keywords",
      title: "Keywords",
    },
  ];

  menus[Role.OPERATOR] = [
    {
      path: "/dashboard",
      title: "Dashboard",
    },
    {
      path: "/store",
      title: "Store Management",
    },
    {
      path: "/playback",
      title: "Playback",
    },
    {
      path: "/transcript-tasks",
      title: "Transcript Tasks",
    },
    {
      path: "/keywords",
      title: "Keywords",
    },
  ];

  menus[Role.QA] = [
    {
      path: "/playback",
      title: "Playback",
    },
    {
      path: "/transcript-tasks",
      title: "Transcript Tasks",
    },
  ];

  menus[Role.STORE] = [
    {
      path: "/playback",
      title: "Playback",
    },
  ];

  menus[Role.ACCOUNT] = [
    {
      path: "/user",
      title: "User Management",
    },
  ];

  return (
    <div className={styles.container}>
      <img src={LogoImage} className={styles.logo} />
      {/* <Logo /> */}

      {currentUser && (
        <>
          <div className={styles.menu_container}>
            {menus[currentUser.role].map((item, idx) => {
              return (
                <div
                  className={styles.menu_item_container}
                  key={idx}
                  style={{
                    background:
                      item.path === location.pathname ? "#1B9CFC" : null,
                  }}
                >
                  <Link to={item.path} className={styles.text_menu}>
                    {item.title}
                  </Link>
                </div>
              );
            })}
          </div>
          <Avatar />
        </>
      )}
    </div>
  );
}

export default Header;
