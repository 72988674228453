import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  filterName: null,
  filterStatus: null,
  filterStore: null,
  filterStoreName: null,
  filterMac: null,
  filterUserName: null,
  filterTags: ["enable"],
  previewImage: null,
  selectedRowKeys: [],
  page: 1,
  pageSize: 20,
};
const monitoringSlice = createSlice({
  name: "monitoring",
  initialState: initialState,
  reducers: {
    changeFilterName: (state, action) => {
      state.filterName = action.payload;
    },
    changeFilterStatus: (state, action) => {
      state.filterStatus = action.payload;
    },
    changeFilterStore: (state, action) => {
      state.filterStore = action.payload;
    },
    changeFilterStoreName: (state, action) => {
      state.filterStoreName = action.payload;
    },
    changeFilterTags: (state, action) => {
      state.filterTags = action.payload;
    },
    changeFilterMac: (state, action) => {
      state.filterMac = action.payload;
    },
    changeFilterUserName: (state, action) => {
      state.filterUserName = action.payload;
    },
    setPreviewImage: (state, action) => {
      state.previewImage = action.payload;
    },
    setPage: (state, action) => {
      state.page = action.payload.page;
      state.pageSize = action.payload.pageSize;
    },
    setSelectedRowKeys: (state, action) => {
      state.selectedRowKeys = action.payload;
    },
  },
});

export const monitoringAction = monitoringSlice.actions;

export default monitoringSlice.reducer;
