import React from "react";
import styles from "./style.module.css";
import Header from "./components/Header";

function Layout({ children }) {
  return (
    <div className={styles.container}>
      <Header />
      <div className={styles.main}>{children}</div>
    </div>
  );
}

export default Layout;
