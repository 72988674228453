import PerfectScrollBar from 'react-perfect-scrollbar';
import styles from './style.module.css';
import ListKeyword from './components/ListKeyword';

function Keywords() {
  return (
    <div className={styles.container}>
      <PerfectScrollBar>
        <ListKeyword />
      </PerfectScrollBar>
    </div>
  );
}

export default Keywords;
