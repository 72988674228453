import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import store from "store/index";
import "./index.css";
import App from "./App";

// const root = ReactDOM.createRoot(document.getElementById("root"));
// root.render(
//   <Provider store={store}>
//     <BrowserRouter>
//       <Suspense fallback={<span>Loading...</span>}>
//         <App />
//       </Suspense>
//     </BrowserRouter>
//   </Provider>
// );

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <Suspense fallback={<span>Loading...</span>}>
        <App />
      </Suspense>
    </BrowserRouter>
  </Provider>,
  document.getElementById("root")
);
