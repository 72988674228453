import React, { useState } from "react";
import { Input, Modal, Form } from "antd";
import { useAuthProvider } from "contexts/authenticationContext";

import styles from "./ChangePassword.module.css";
import {
  showErrorNotification,
  showSuccessNotification,
} from "components/ui/Notification";
import authApi from "apis/authentication";

function ChangePassword({ visible, setVisible }) {
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const { logout } = useAuthProvider();

  const handleClose = () => {
    setVisible(false);
  };

  const handleOk = () => {
    form.submit();
  };

  const handleSubmitForm = (values) => {
    const payload = {
      old_password: values.old_password,
      new_password: values.new_password,
      password_confirm: values.confirm_password,
    };

    setLoading(true);
    authApi
      .chagePassword(payload)
      .then((resp) => {
        setLoading(false);
        showSuccessNotification("Change password has successfully");
        setVisible(false);
        logout();
      })
      .catch((err) => {
        setLoading(false);
        showErrorNotification(err.detail);
      });
  };

  return (
    <Modal
      title="Change password"
      visible={visible}
      onOk={handleOk}
      onCancel={handleClose}
      okText="Save"
      width={500}
      okButtonProps={{ loading: loading }}
    >
      <div className={styles.container}>
        <Form
          form={form}
          name="change_password"
          onFinish={handleSubmitForm}
          layout="vertical"
        >
          <Form.Item
            name="old_password"
            label="Old password"
            rules={[
              {
                required: true,
                message: "Please input old password",
              },
            ]}
          >
            <Input.Password placeholder="Old password" />
          </Form.Item>

          <Form.Item
            name="new_password"
            label="New password"
            rules={[
              {
                required: true,
                message: "Please input new password",
              },
            ]}
          >
            <Input.Password placeholder="New password" />
          </Form.Item>

          <Form.Item
            name="confirm_password"
            label="Confirm password"
            rules={[
              {
                required: true,
                message: "Please input confirm password",
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue("new_password") === value) {
                    return Promise.resolve();
                  }

                  return Promise.reject(
                    new Error(
                      "The two passwords that you entered do not match!"
                    )
                  );
                },
              }),
            ]}
          >
            <Input.Password placeholder="Confirm password" />
          </Form.Item>
        </Form>
        <span className={styles.text_small}>
          After change password, you must re-login
        </span>
      </div>
    </Modal>
  );
}

export default ChangePassword;
