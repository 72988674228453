import "./App.css";

import Router from "routers";
import { AuthProvider } from "contexts/authenticationContext";

function App() {
  return (
    <div className="App">
      <AuthProvider>
        <Router />
      </AuthProvider>
    </div>
  );
}

export default App;
